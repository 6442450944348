var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("組織資料中心")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("SQL 排程管理")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"d-flex mb-4 mb-xl-2 justify-content-between"},[_c('div',[_c('h4',{staticClass:"mb-2 font-weight-bold"},[_vm._v(" "+_vm._s(("【" + (_vm.organization.name) + "】SQL 排程管理"))+" ")])]),_c('div',[_c('b-input-group',{staticClass:"mb-2"},[(_vm.$permissions.has(_vm.$permissions.consts.SQL_RUNNER_MODIFY))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{ name: 'SqlRunnerCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增 SQL 排程")]):_vm._e()],1)],1)]),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.sqlRunners,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [(
                    _vm.$permissions.has(_vm.$permissions.consts.SQL_RUNNER_EXECUTE)
                  )?_c('b-button',{attrs:{"variant":"inverse-success"},on:{"click":function($event){return _vm.executeSqlRunner(data.item)}}},[_c('span',{staticClass:"mdi mdi-autorenew mr-1"}),_vm._v("執行 ")]):_vm._e(),_c('b-button',{attrs:{"variant":"inverse-primary","to":{
                    name: 'SqlRunnerView',
                    params: { sql_runner_id: data.item.id },
                  }}},[_c('span',{staticClass:"mdi mdi-eye"}),_vm._v(" 查看 ")]),(
                    _vm.$permissions.has(_vm.$permissions.consts.SQL_RUNNER_MODIFY)
                  )?_c('b-button',{attrs:{"variant":"inverse-warning","to":{
                    name: 'SqlRunnerEdit',
                    params: { sql_runner_id: data.item.id },
                  }}},[_c('span',{staticClass:"mdi mdi-lead-pencil"}),_vm._v(" 編輯 ")]):_vm._e(),(
                    _vm.$permissions.has(_vm.$permissions.consts.SQL_RUNNER_MODIFY)
                  )?_c('b-button',{attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.deleteSqlRunner(data.item)}}},[_c('span',{staticClass:"mdi mdi-delete"}),_vm._v(" 刪除 ")]):_vm._e()]}}])})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center"},on:{"change":_vm.getSqlRunners},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }