import https from "./https"
import store from "@/store"

const sqlRunnerApi = {
  getSqlRunners (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/sql-runners`, { params })
  },
  removeSqlRunner (sqlRunner) {
    const organization = store.state.general.organization
    return https.delete(`admin/organizations/${organization.id}/sql-runners/${sqlRunner.id}`)
  },
  storeSqlRunner (params) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/sql-runners`, params)
  },
  getSqlRunner (sqlRunnerId) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/sql-runners/${sqlRunnerId}`)
  },
  updateSqlRunner (sqlRunner) {
    console.log('sqlRunner', sqlRunner)
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/sql-runners/${sqlRunner.id}`, sqlRunner)
  },
  execute(sqlRunner) {
    const organization = store.state.general.organization
    return https.post(`admin/organizations/${organization.id}/sql-runners/${sqlRunner.id}/execute`)
  }
}

export default sqlRunnerApi
